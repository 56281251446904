// UniversitySearchComponent.tsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; 
import { Box, TextField, Typography, Paper } from '@mui/material';

interface University {
  name: string;
  id: number;
}

const UniversitySearchComponent: React.FC<{ onSelectUniversity: (universityName: string) => void }> = ({ onSelectUniversity }) => {
  const { t } = useTranslation(); 
  const [searchQuery, setSearchQuery] = useState(t('Soongsil University'));
  const [universities, setUniversities] = useState<University[]>([]);
  const [matchedUniversity, setMatchedUniversity] = useState<string>('');


  // useEffect(() => {
  //   const fetchUniversities = async () => {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/register/universities`);
  //       const universitiesArray: University[] = Object.entries(response.data).map(([name]) => ({ name, id: response.data[name] }));
  //       setUniversities(universitiesArray);
  //     } catch (error) {
  //       console.error('Error fetching universities:', error);
  //     }
  //   };

  //   fetchUniversities();
  // }, []);

  useEffect(() => {
    
    const match = universities.find(uni => uni.name.toLowerCase().startsWith(searchQuery.toLowerCase()));
    if (match && searchQuery) {
      setMatchedUniversity(match.name);
    } else {
      setMatchedUniversity('');
    }
  }, [searchQuery, universities]);

  const handleSuggestionClick = (suggestedName: string) => {
    setSearchQuery(suggestedName); 
    setMatchedUniversity(''); 
    onSelectUniversity(suggestedName); 
  };

  return (
    <Box
      sx={{
        mt: 2,
        p: 2,
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        backgroundColor: '#f9f9f9',
      }}
      component={Paper}
    >
      <TextField
        label={t('Search for a university...')}
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        disabled
        sx={{ mb: 2 }}
      />
      {matchedUniversity && searchQuery !== matchedUniversity && (
        <Box
          sx={{
            cursor: 'pointer',
            padding: '10px 20px',
            border: '1px solid #ddd',
            borderRadius: '4px',
            backgroundColor: '#f0f0f0',
            '&:hover': {
              backgroundColor: '#e0e0e0',
            }
          }}
          onClick={() => handleSuggestionClick(matchedUniversity)}
        >
          <Typography variant="body1">
            <strong>{searchQuery}</strong>{matchedUniversity.substring(searchQuery.length)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default UniversitySearchComponent;
