// src/store/socketStore.ts

import { create } from 'zustand';
import { io, Socket } from 'socket.io-client';


interface SocketState {
  socket: Socket | null;
  initializeSocket: () => Socket;
}

const useSocketStore = create<SocketState>((set, get) => ({
  socket: null,
  initializeSocket: () => {
    let socket = get().socket;
    if (!socket) {
      socket = io(`${process.env.REACT_APP_BACKEND_URL}`, {
        withCredentials: true, // Include cookies
        autoConnect: true,    // Prevent auto-connection
      });
      set({ socket });
    }
    return socket;
  },
}));

export default useSocketStore;



// let socket: Socket;

// const useSocketStore = create<SocketState>(() => {
//   if (!socket) {
//     socket = io(`${process.env.REACT_APP_BACKEND_URL}`, {
//       transports: ['websocket'],
//       withCredentials: true,
//     });
//   }
//   return {
//     socket,
//   };
// });


// interface SocketState {
//   socket: Socket;
// }

// export default useSocketStore;
