// PostList.tsx
import React, { useState, useEffect, memo } from 'react';
import axios from 'axios';
import Post from './Post'; 
import { PostType } from './types'; 
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSearchContext } from '@contexts/SearchContext';
import { Box, Button, IconButton, Select, MenuItem, Typography } from '@mui/material';
import { ArrowForward, Create } from '@mui/icons-material';

import colors from '@styles/colors';


const PostList: React.FC = () => {
  const [posts, setPosts] = useState<PostType[]>([]);
  const [sortOption, setSortOption] = useState<string>('date');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [postsPerPage] = useState<number>(10);
  const { t, i18n } = useTranslation();
  const { searchResults } = useSearchContext();
  const fetchPosts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/posts/`, {
        params: {lang:i18n.language}, 
        withCredentials: true,
      });
      setPosts(response.data); 
      
      console.log(response.data)
    } catch (error) {
      console.error("Failed to fetch posts:", error);
    }
  };
  const filterByCategory = (posts: PostType[]) => {
    if (selectedCategory) {
      return posts.filter(post => post.category === selectedCategory);
    }
    return posts;
  };



  const sortedPosts = () => {
    let relevantPosts = searchResults.length > 0 ? searchResults : posts;
    relevantPosts = filterByCategory(relevantPosts);

    return relevantPosts.slice().sort((a, b) => {
      if (sortOption === 'date') {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      } else if (sortOption === 'likes') {
        if (b.likes === a.likes) {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        }
        return b.likes - a.likes;
      }
      return 0;
    });
  };
  const relevantPosts = sortedPosts();
  const totalPages = Math.ceil(relevantPosts.length / postsPerPage);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = relevantPosts.slice(indexOfFirstPost, indexOfLastPost);
  const handleClick = (number: number) => {
    if (number > 0 && number <= totalPages) {
      setCurrentPage(number);
    }
  };

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category === selectedCategory ? null : category); 
    setCurrentPage(1); 
  };



  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(relevantPosts.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  useEffect(() => {
    fetchPosts();
  }, [currentPage, i18n.language]);

  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {['daily', 'academic', 'career'].map(category => (
            <Button
              key={category}
              variant={selectedCategory === category ? 'contained' : 'outlined'}
              onClick={() => handleCategoryClick(category)}
              sx={{
                borderRadius: '8px',
                height: '40px',
                transition: 'background-color 0.3s ease, color 0.3s ease',
                backgroundColor: selectedCategory === category ? colors.mainColor : 'transparent',
                color: selectedCategory === category ? '#ffffff' : colors.mainColor,
                border: `1px solid ${colors.mainColor}`,
                '&:hover': {
                  backgroundColor: selectedCategory === category ? colors.accentColor : `${colors.mainColor}20`,
                  color: selectedCategory === category ? '#ffffff' : colors.mainColor,
                },
              }}
            >
              {t(`PostList.${category}`)}
            </Button>
          ))}
        </Box>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Select
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
            variant="outlined"
            sx={{ 
              borderRadius: '8px',
              height: '40px',
              color: colors.mainColor,
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.mainColor,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.accentColor,
              },
            }}
          >
            <MenuItem value="date">{t('Latest')}</MenuItem>
            <MenuItem value="likes">{t('Most Liked')}</MenuItem>
          </Select>
          <Button
            component={Link}
            to="/community/write"
            startIcon={<Create />}
            sx={{
              textDecoration: 'none',
              borderRadius: '8px',
              p: 1,
              color: colors.mainColor,
              boxShadow: '0 0 2px black',
              '&:hover': {
                backgroundColor: colors.mainColor,
                color: '#ffffff',
              },
            }}
          >
            {t('Write')}
          </Button>
        </Box>
      </Box>

      {currentPosts.map((post) => (
        <MemoizedPost key={post.id} {...post} />
      ))}

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        {Array.from({ length: totalPages }, (_, index) => index + 1).map((number) => (
          <Button
            key={number}
            onClick={() => handleClick(number)}
            variant={currentPage === number ? 'contained' : 'outlined'}
            sx={{
              borderRadius: '8px',
              m: '0 4px',
              width: '40px',
              height: '40px',
              backgroundColor: currentPage === number ? colors.mainColor : 'transparent',
              color: currentPage === number ? '#ffffff' : colors.mainColor,
              border: `1px solid ${colors.mainColor}`,
              '&:hover': {
                backgroundColor: currentPage === number ? colors.accentColor : colors.mainColor,
                color: '#ffffff',
              },
            }}
          >
            {number}
          </Button>
        ))}
        <IconButton
          onClick={() => handleClick(currentPage + 1)}
          disabled={currentPage === totalPages}
          size="small"
          sx={{
            color: colors.mainColor,
            '&:hover': {
              color: colors.accentColor,
            },
          }}
        >
          <ArrowForward />
        </IconButton>
      </Box>
    </Box>
  );
};

const MemoizedPost = memo(Post);

export default PostList;
