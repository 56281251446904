import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
// Define the structure for our form data
interface FormData {
  username: string;
  email: string;
  password: string;
  university?: string; // Make university optional
}

// Define the return type of the hook
interface UseFormSubmitReturn {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  isLoading: boolean;
  error: string | null;
}

const useFormSubmit = (initialFormData: FormData): UseFormSubmitReturn => {
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/register`, formData);
      alert('Registration successful!');
      setFormData(initialFormData); // Reset form data
      navigate('/'); 
    } catch (error) {
      console.log('Error details:', error); // Log the error object for debugging
      if (axios.isAxiosError(error)) {
        console.log('Axios error response:', error.response); // More detailed Axios error response
        setError(error.response?.data.error || 'An unexpected error occurred');
      } else {
        setError('An unexpected error occurred');
      }
      setIsLoading(false);
    }
  };

  return { formData, setFormData, handleInputChange, handleSubmit, isLoading, error };
};

export default useFormSubmit;