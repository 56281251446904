// src/components/RegisterForm.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import useFormSubmit from './useFormSubmit'; // Custom hook import
import useValidation from './useValidation'; // Custom hook import
import UniversitySearchComponent from './UniversitySearchComponent'; // UniversitySearchComponent import
import { Box, Button, Typography, TextField, Paper } from '@mui/material';

const RegisterForm: React.FC = () => {
  const { t } = useTranslation();
  const initialFormData = { username: '', email: '', password: '', university: '' };
  const { formData, setFormData, handleInputChange, handleSubmit, isLoading, error } = useFormSubmit(initialFormData);
  const { errors, validationStatus, checkDuplication } = useValidation();

  const handleSelectUniversity = (universityName: string) => {
    setFormData({ ...formData, university: universityName });
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 4,
        maxWidth: '500px',
        margin: '0 auto',
        borderRadius: 2,
        boxShadow: 3,
        bgcolor: '#fff',
      }}
    >
      {error && <Typography color="error">{error}</Typography>}
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <TextField
          label={t('Username')}
          name="username"
          value={formData.username}
          onChange={handleInputChange}
          onBlur={() => checkDuplication('username', formData.username)}
          error={!!errors.username}
          helperText={errors.username}
          fullWidth
          margin="normal"
        />
        <TextField
          label={t('Email')}
          name="email"
          type="email"
          value={formData.email}
          onChange={handleInputChange}
          onBlur={() => checkDuplication('email', formData.email)}
          error={!!errors.email}
          helperText={errors.email}
          fullWidth
          margin="normal"
        />
        <TextField
          label={t('Password')}
          name="password"
          type="password"
          value={formData.password}
          onChange={handleInputChange}
          onBlur={() => checkDuplication('password', formData.password)}
          error={!!errors.password}
          helperText={errors.password}
          fullWidth
          margin="normal"
        />
        <UniversitySearchComponent onSelectUniversity={handleSelectUniversity} />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            mt: 3,
            bgcolor: '#061E30',
            color: 'white',
            '&:hover': {
              bgcolor: '#051825',
            },
          }}
          disabled={isLoading}
        >
          {t('Register')}
        </Button>
      </form>
    </Paper>
  );
};

export default RegisterForm;
