import React from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; 
import { TextField } from '@mui/material';


interface TitleNlpAnalyzerProps {
  title: string;
  onTitleChange: (title: string) => void;
  onTitleAnalysis: (detectedLanguage: string, category: string, engTranslatedTitle:string) => void;
}

const TitleNlpAnalyzer: React.FC<TitleNlpAnalyzerProps> = ({ title, onTitleChange, onTitleAnalysis }) => {
  const { t } = useTranslation(); 

  const handleBlur = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/titlenlp/analyze`, { title });
      console.log('Title analysis response:', response.data);
      const { detected_language, category, engTranslatedTitle } = response.data;
      onTitleAnalysis(detected_language, category, engTranslatedTitle);
    } catch (error) {
      console.error('Error analyzing title:', error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onTitleChange(value);
  };

  return (
    <TextField
      label={t('Title')}
      value={title}
      onChange={handleChange}
      onBlur={handleBlur}
      variant="outlined"
      fullWidth
      margin="dense"
    />
  );
};

export default TitleNlpAnalyzer;
