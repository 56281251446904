// src/components/post/PostDetail.tsx

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { formatDate } from '@utils/formatDate';
import Comments from './Comments';
import { PostType} from './types'; 
import { useTranslation } from 'react-i18next'; 

import { Container, Typography, Box, Chip, CircularProgress, Avatar, Stack, IconButton, Menu, MenuItem } from '@mui/material';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';  
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useNavigate } from 'react-router-dom';
import useAuthStore from '@store/authStore';
import TranslationSuggestionModal from './TranslationSuggestionModal';



const PostDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [post, setPost] = useState<PostType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { t, i18n } = useTranslation();
  const [likes, setLikes] = useState<number>(0);
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const { user } = useAuthStore();
  const navigate = useNavigate(); 
  const [isAuthor, setIsAuthor] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  
  const open = Boolean(anchorEl);
  const [originalPost, setOriginalPost] = useState<PostType | null>(null); 
  const [translationModalOpen, setTranslationModalOpen] = useState<boolean>(false);
  const originalPostCache = useRef<{ [key: string]: PostType }>({});


  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  

  const fetchPost = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/posts/${id}`,
        {params:{lang:i18n.language}, withCredentials: true});
      setPost(response.data);
      setLikes(response.data.likes); 
      setIsLiked(response.data.isLikedByUser);
      if (response.data && user) { 
        setIsAuthor(user.id === response.data.user_id);
      }

    } catch (err) {
      setError("Failed to fetch the post.");
    } finally {
      setLoading(false);
    }
    
  };

  
  useEffect(() => {
    fetchPost();
  }, [id, i18n.language]);


  useEffect(() => {
    const fetchOriginalPost = async () => {
      if (!id) return;

      // Check if the original post is already cached
      if (originalPostCache.current[id]) {
        setOriginalPost(originalPostCache.current[id]);
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/posts/${id}`,
          { params: { lang: 'org' }, withCredentials: true }
        );
        setOriginalPost(response.data);
        // Cache the original post
        originalPostCache.current[id] = response.data;
      } catch (err) {
        console.error("Failed to fetch the original post.", err);
        // Optionally, set an error state for originalPost if needed
      }
    };

    fetchOriginalPost();
  }, [id]);



  const handleDelete = async () => {
    if (window.confirm(t('Are you sure you want to delete this post?'))) {
      try {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/posts/${id}`, {
          withCredentials: true,
        });
        navigate('/community');
      } catch (err) {
        console.error("Failed to delete the post.", err);
      }
    }
  };

  const handleLike = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/posts/${id}/like`,
        {},{withCredentials: true}
      );
      setLikes(response.data.like_count);
      setIsLiked(response.data.isLiked);
      } catch (err) {
      console.error("Failed to like/unlike the post.");
    }
  };
    



  if (loading) return (
    <Container maxWidth="md" sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </Container>
  );
  if (error) return <p>{t(error)}</p>;
  if (!post) return <p>{t('No post found.')}</p>;
    
  
  const formattedDate = formatDate(post.date);
  // console.log("post.language is ",post.language);
  // console.log("i18n.language is ",i18n.language);
// TODO unified LANGUAGE CODE!!

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Box sx={{ mb: 3 }}>
          <Chip
            label={t(`PostList.${post.category}`)}
            color="primary"
            sx={{
              height: '2rem',
              borderRadius: '4px',
              mb: 2
            }}
          />
          <Typography variant="h4" component="h1" sx={{ 
            fontWeight: 'bold',
            wordBreak: 'break-word'
          }}>
            {post.title}
          </Typography>
        </Box>

        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 4
        }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar sx={{ bgcolor: 'primary.main' }}>
              {post.username[0].toUpperCase()}
            </Avatar>
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {post.username}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {formattedDate}
              </Typography>
            </Box>
          </Stack>

          <Box>
            <IconButton onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {isAuthor && (
                <MenuItem onClick={() => {
                  handleDelete();
                  handleMenuClose();
                }} sx={{ color: 'error.main' }}>
                  {t('Delete')}
                </MenuItem>
              )}
              {/* Added label for the Translation MenuItem */}
              {i18n.language !== 'org' &&(
              <MenuItem
                onClick={() => {
                  setTranslationModalOpen(true);
                  handleMenuClose();
                }}
              >
                {t('Improve Translation')}
              </MenuItem>
            )}
            </Menu>
          </Box>
        </Box>

        {/* Post Content */}
        <Typography variant="body1" sx={{ 
          whiteSpace: 'pre-wrap', 
          mb: 3,
          lineHeight: 1.7
        }}>
          {post.contents}
        </Typography>

        {/* Like Button */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <IconButton 
            onClick={handleLike} 
            color={isLiked ? 'primary' : 'default'}
            sx={{ '&:hover': { bgcolor: 'action.hover' } }}
          >
            {isLiked ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
          </IconButton>
          <Typography variant="body2" sx={{ ml: 1 }}>
            {likes}
          </Typography>
        </Box>

        {/* Hashtags */}
        {post.hashtags && post.hashtags.length > 0 && (
          <Box sx={{ mt: 2 }}>
            {post.hashtags.map((hashtag_, index) => (
              <Chip 
                key={index} 
                label={`#${hashtag_.content}`} 
                sx={{ 
                  mr: 1, 
                  mb: 1, 
                  borderRadius: '8px',
                  bgcolor: 'action.selected',
                  '&:hover': {
                    bgcolor: 'action.focus'
                  }
                }} 
              />
            ))}
          </Box>
        )}

        <Divider sx={{ my: 3 }} />
        
        {/* Comments Section */}
        <Box sx={{ mt: 3 }}>
          <Comments postId={post.id} />
        </Box>
      </Paper>
      {translationModalOpen && originalPost && (
        <TranslationSuggestionModal
          open={translationModalOpen}
          handleClose={() => setTranslationModalOpen(false)}
          postId={post.id}
          language={post.language}
          originalTitle={originalPost.title}
          originalContents={originalPost.contents}
          translatedTitle={post.title}
          translatedContents={post.contents}
        />
      )}
    </Container>
  );
};


export default PostDetail;
