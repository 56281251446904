
import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import './CommentWrite.css';

interface CommentWriteProps {
  postId: number;
  onCommentAdded: () => void; 
}

const CommentWrite: React.FC<CommentWriteProps> = ({ postId, onCommentAdded }) => {
  const [username, setUsername] = useState<string>('');
  const [contents, setContents] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation(); // Use useTranslation hook


  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!username || !contents) {
      setError(t('Please fill in all fields.'));
      return;
    }



    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/posts/${postId}/comments`, {
        username,
        contents
      });
      setUsername('');
      setContents('');
      setError(null);
      onCommentAdded(); 
    } catch (err) {
      setError(t('Failed to submit comment.'));
    }
  };

  return (
    <div className="comment-write">
      <h3>{t('Write a Comment')}</h3>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
        <label htmlFor="username">{t('Username')}:</label>
        <input
            id="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
        <label htmlFor="contents">{t('Content')}:</label>
        <textarea
            id="contents"
            value={contents}
            onChange={(e) => setContents(e.target.value)}
          />
        </div>
        <button type="submit">{t('Submit Comment')}</button>
      </form>
    </div>
  );
};

export default CommentWrite;
