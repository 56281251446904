// src/components/Profile/Profile.tsx

import React from 'react';
import useAuthStore from '@store/authStore';
import { useNavigate } from 'react-router-dom';

const Profile: React.FC = () => {
  const { user, isAuthenticated, logout } = useAuthStore(); // Zustand hook
  const navigate = useNavigate(); // React Router's navigate function

  const handleLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/login/logout`, {
        method: 'POST', 
        credentials: 'include', 
      });
      if (response.ok) {
        logout(); // This handles clearing auth state and redirects
        navigate('/'); // Navigate to the top path
      } else {
        console.error('Failed to logout');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
    alert('Logging out');
  };

  if (!isAuthenticated) {
    return (
      <div className="profile-container">
        <h2>User is not authenticated</h2>
        {/* Redirect to login or display a message */}
      </div>
    );
  }

  return (
    <div className="profile-container">
      <h1>Profile Page</h1>
      <div className="profile-info">
        <p><strong>Username:</strong> {user?.username}</p>
        {/* Additional user information */}
      </div>
      <button onClick={handleLogout} className="logout-button">Logout</button>
    </div>
  );
};

export default Profile;
