import { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; 

const useValidation = () => {
  const { t } = useTranslation(); 
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [validationStatus, setValidationStatus] = useState<{ [key: string]: boolean }>({});

  const updateState = (field: string, isValid: boolean, message?: string) => {
    setValidationStatus(prev => ({ ...prev, [field]: isValid }));
    setErrors(prev => ({ ...prev, [field]: message || '' }));
  };

  const validators = {
    username: (value: string) => value.length >= 2 || t('Username must be at least 2 characters long.'),
    email: (value: string) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) || t('Invalid email format.'),
    password: (value: string) => value.length >= 4 || t('Password must be at least 4 characters long.'),
  };

  const checkDuplication = async (field: 'username' | 'email' | 'password', value: string) => {
    if (!value.trim()) return updateState(field, false, t('This field is required.'));

    const validationMessage = validators[field] && validators[field](value);
    if (typeof validationMessage === 'string') return updateState(field, false, validationMessage);

    if (field === 'password') {
      // Password does not need duplication check
      return updateState(field, true);
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/register/check-duplication?${field}=${encodeURIComponent(value)}`);
      const isDuplicate = response.data.isDuplicate;
      isDuplicate 
      ? updateState(field, false, t(`${field} is already in use.`)) 
      : updateState(field, true);
    } catch (error) {
      console.error(`Error checking duplication for ${field}:`, error);
      updateState(field, false, t('Error occurred while checking duplication.'));
    }
  };

  return { errors, validationStatus, checkDuplication };
};

export default useValidation;
